.rtf {
  box-sizing: border-box;
  position: fixed;
  white-space: nowrap;
  z-index: 9998;
  padding-left: 0;
  list-style: none;
  left: 0;
}

.rtf.open .rtf--mb>* {
  transform-origin: center center;
  transform: rotate(315deg);
  transition: transform 0.2s ease-in-out;
}

.rtf.open .rtf--mb>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rtf.open .rtf--ab__c:hover>span,
.rtf.open .rtf--ab__c>span.always-show {
  transition: opacity 0.2s ease-in-out;
  opacity: 0.9;
}

.rtf.open .rtf--ab__c:first-child {
  transform: translateY(-60px) scale(1);
  transition-delay: 0.02s;
}

.rtf.open .rtf--ab__c:first-child.top {
  transform: translateY(60px) scale(1);
}

.rtf.open .rtf--ab__c:nth-child(2) {
  transform: translateY(-120px) scale(1);
  transition-delay: 0.06s;
}

.rtf.open .rtf--ab__c:nth-child(2).top {
  transform: translateY(120px) scale(1);
}

.rtf.open .rtf--ab__c:nth-child(3) {
  transform: translateY(-180px) scale(1);
  transition-delay: 0.08s;
}

.rtf.open .rtf--ab__c:nth-child(3).top {
  transform: translateY(180px) scale(1);
}

.rtf.open .rtf--ab__c:nth-child(4) {
  transform: translateY(-240px) scale(1);
  transition-delay: 0.10s;
}

.rtf.open .rtf--ab__c:nth-child(4).top {
  transform: translateY(240px) scale(1);
}

.rtf.open .rtf--ab__c:nth-child(5) {
  transform: translateY(-300px) scale(1);
  transition-delay: 0.12s;
}

.rtf.open .rtf--ab__c:nth-child(5).top {
  transform: translateY(300px) scale(1);
}

.rtf.open .rtf--ab__c:nth-child(6) {
  transform: translateY(-360px) scale(1);
  transition-delay: 0.14s;
}

.rtf.open .rtf--ab__c:nth-child(6).top {
  transform: translateY(360px) scale(1);
}

.rtf--mb__c {
  padding: 25px;
  margin: -25px;
}

.rtf--mb__c :last-child {
  margin-bottom: 0;
}

.rtf--mb__c:hover>span,
.rtf--mb__c>span.always-show {
  transition: opacity 0.2s ease-in-out;
  opacity: 0.9;
}

.rtf--mb__c>span {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 6px;
  margin-left: 4px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px 4px;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.rtf--mb__c>span.right {
  right: 100%;
}

.rtf--mb {
  height: 56px;
  width: 56px;
  z-index: 9999;
  background-color: #666;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: 700;
  color: #f1f1f1;
  font-size: 18px;
}

.rtf--ab__c,
.rtf--mb>* {
  transition: transform 0.2s ease-in-out;
}

.rtf--ab__c {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: -10px 0;
}

.rtf--ab__c>span {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 6px;
  background: var(--primary-color);
  padding: 2px 4px;
  border-radius: 2px;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  color: #fff;
  border-radius: 10px;
  font-size: 13px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.rtf--ab__c>span.right {
  right: 100%;
}

.rtf--ab__c:first-child {
  transform: translateY(-60px) scale(0);
  transition-delay: 0.21s;
}

.rtf--ab__c:first-child.top {
  transform: translateY(60px) scale(0);
}

.rtf--ab__c:nth-child(2) {
  transform: translateY(-120px) scale(0);
  transition-delay: 0.18s;
}

.rtf--ab__c:nth-child(2).top {
  transform: translateY(120px) scale(0);
}

.rtf--ab__c:nth-child(3) {
  transform: translateY(-180px) scale(0);
  transition-delay: 0.15s;
}

.rtf--ab__c:nth-child(3).top {
  transform: translateY(180px) scale(0);
}

.rtf--ab__c:nth-child(4) {
  transform: translateY(-240px) scale(0);
  transition-delay: 0.12s;
}

.rtf--ab__c:nth-child(4).top {
  transform: translateY(240px) scale(0);
}

.rtf--ab__c:nth-child(5) {
  transform: translateY(-300px) scale(0);
  transition-delay: 0.09s;
}

.rtf--ab__c:nth-child(5).top {
  transform: translateY(300px) scale(0);
}

.rtf--ab__c:nth-child(6) {
  transform: translateY(-360px) scale(0);
  transition-delay: 0.03s;
}

.rtf--ab__c:nth-child(6).top {
  transform: translateY(360px) scale(0);
}

.rtf--ab {
  height: 48px;
  width: 48px;
  background-color: #aaa;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: 700;
  color: #f1f1f1;
  margin-right: 4px;
  font-size: 16px;
  z-index: 10000;
}

.rtf--ab img {
  width: 20px;
  height: 20px;
}

.rtf {
  margin-bottom: 5px !important;
  z-index: 10 !important;
}